'use strict';

var viewer = null;

function initMap() {
  if (typeof google !== 'undefined') {
    const myLatLng = {lat: 49.685543, lng: 21.779893};
    const mapDiv = document.getElementsByClassName('section--contact__map')[0];

    const map = new google.maps.Map(mapDiv, {
      center: myLatLng,
      zoom: 15
    });

    const marker = new google.maps.Marker({
      position: myLatLng,
      map: map,
      title: 'Agenda Krosno'
    });
  }
}

function manageMenuToggle() {
  const navTrigger = $('.js-nav__trigger-button');
  const navResponsiveList = $('.js-primary-header__nav__list-responsive');
  const navElements = $('.js-primary-header__nav__list-responsive .header__nav__element');

  navTrigger.click(function () {
    toggleNavTrigger();
    navResponsiveList.slideToggle();
  });

  navElements.click(function () {
    toggleNavTrigger();
    navResponsiveList.slideUp();
  });
}

function toggleNavTrigger() {
  const navTrigger = $('.js-nav__trigger-button');
  if (navTrigger.hasClass('is-triggered')) {
    navTrigger.removeClass('is-triggered');
  } else {
    navTrigger.addClass('is-triggered');
  }
}

function handleNavigation() {
  $('.js-header__nav__element').on('click', function () {
    const scrollDestElementID = this.dataset.scroll_dest;
    console.log('tes')
    $('.js-nav__trigger-button').removeClass('is-triggered');
    $('.js-primary-header__nav__list').removeClass('is-open');

    setTimeout(function () {
      const scrollValue = $("#" + scrollDestElementID).offset().top - $('.header__wrapper').height();

      $('html, body').animate({
        scrollTop: scrollValue
      }, 1000);
    }, 500);

    $(".section--contact__info").addClass('animate')
    setTimeout(function () {
      $(".section--contact__info").removeClass('animate')
    }, 2000)
  })
}


function initCarousels() {
  $('.slider').slick({
    dots: false,
    infinity: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<i class="fas fa-arrow-left 2x"></i>',
    nextArrow: '<i class="fas fa-arrow-right 2x"></i>',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ],
    asNavFor: '.slider-for'
  });

  $('.slider-for').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.slider',
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    prevArrow: '',
    nextArrow: '',
  })

  $('.slider').mouseover(function () {
    $('.slider').slick();
  })
}

function handleTabs() {
  $('ul.product__info__list li').click(function () {
    var tabID = $(this).attr('data-tab');

    $('ul.product__info__list li').removeClass('current');
    $('.js-tab-content').removeClass('current');
    $('.js-product__carousel').removeClass('current');
    $('.js-tab2').removeClass('current');

    $(this).addClass('current');
    $('.' + tabID).addClass('current');
  })
}

function initViewer() {
  if (document.querySelector('.js-product-viewer')) {
    new Viewer(document.querySelector('.js-product-viewer'), {
      zoomable: false,
      movable: false,
      inline: true,
      title: false,
      toolbar: false
    });
  }

  if (document.querySelector('.js-product-viewer-2')) {
    new Viewer(document.querySelector('.js-product-viewer-2'), {
      zoomable: false,
      movable: false,
      inline: true,
      title: function () {
        const imagesList = document.querySelectorAll('.js-product-viewer-2 li img');
        return imagesList[this.index].title;
      },
      toolbar: false
    });
  }
}

function toggleContactForm() {
  const contactForm = $('.js-contact-form__wrapper');
  const triggerFormButton = $('.js-trigger-form-btn');

  triggerFormButton.on('click', function () {
    contactForm.slideToggle();
    triggerFormButton.css('display', 'none');

    $('html,body').animate({
        scrollTop: contactForm.offset().top
      },
      'slow');
  })
}

function handleSubmitForm() {
  const formSubject = $('head title').text();
  const formEmail = $('.js-input-email');
  const formContent = $('.js-textarea-content');
  const formPhone = $('.js-input-phone');
  const formRodo = $('.js-input-rodo');
  const submitFormBtn = $('.js-submit-form__button');
  const formWarning = $('.contact-form__warning');
  const contactForm = $('.js-contact-form');
  const contactFormWarning = $('.js-contact-form__warning');
  const loader = $('.loader');

  submitFormBtn.on('click', function () {
    var readyToSubmit = true;

    if (formEmail.val() === '' || formEmail.val().indexOf('@') === -1) {
      readyToSubmit = false
    }
    if (formContent.val() === '') {
      readyToSubmit = false
    }
    if (formPhone.val() === '') {
      readyToSubmit = false;
    }
    if(formRodo.is(':checked') === false) {
      readyToSubmit = false;
    }

    if (readyToSubmit) {
      submitFormBtn.css('display', 'none');
      loader.css('display', 'block');
      $.ajax({
        type: "POST",
        url: 'https://chlorinated-versed-tote.glitch.me/contact',
        data: {
          email: formEmail.val(),
          message: formContent.val(),
          phone: formPhone.val(),
          subject: formSubject,
        },
        success: function (data) {
          if (data.msg === '') {
            contactForm.slideUp();
            contactFormWarning.text('Wiadomość została wysłana')
            contactFormWarning.css('color', 'green').css('display', 'inline')
            contactFormWarning.css('display', 'inline');
          } else {
            contactFormWarning.text('Nie udało się wysłać wiadomości. Spróbuj ponownie lub wyslij zapytanie pod adres biuro@agenda-reklama.pl.');
            contactFormWarning.css('color', 'red').css('display', 'inline')
            contactFormWarning.css('display', 'inline');
          }
        }
      });
    } else {
      submitFormBtn.css('display', 'inline');
      formWarning.text('Uzupełnij wszystkie pola i zaznacz zgodę na przetwarzanie.').css('display', 'inline')
    }
  })
}

function hideRealizationOnHover() {
  $('.visualisation_trigger').hover(
    function () {
      $('.realization_real').addClass('hover')
    },
    function () {
      $('.realization_real').removeClass('hover')
    }
  )
}

function addCookiesMessage() {
  var container = document.createElement('div'),
    link = document.createElement('a');

  container.setAttribute('id', 'cookieinfo');
  container.setAttribute('class', 'cookie-alert');
  container.innerHTML = '<h6>Ta strona wykorzystuje pliki cookie</h6><p>Używamy informacji zapisanych za pomocą plików cookies w celu zapewnienia maksymalnej wygody w korzystaniu z naszego serwisu. Mogą też korzystać z nich współpracujące z nami firmy badawcze oraz reklamowe. Jeżeli wyrażasz zgodę na zapisywanie informacji zawartej w cookies kliknij na &bdquo;x&rdquo; w prawym górnym rogu tej informacji. Jeśli nie wyrażasz zgody, ustawienia dotyczące plików cookies możesz zmienić w swojej przeglądarce.</p>';

  link.setAttribute('href', '#');
  link.setAttribute('title', 'Zamknij');
  link.innerHTML = 'x';

  function clickHandler(e) {
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    localStorage.setItem('cookies_accepted', 'true');
    document.body.removeChild(container);
  }

  if (link.addEventListener) {
    link.addEventListener('click', clickHandler);
  } else {
    link.attachEvent('onclick', clickHandler);
  }

  container.appendChild(link);
  document.body.appendChild(container);

  return true;
}


function isDev() {
  return window.location.origin == 'http://localhost'
}

function isItMe() {
  var isItMe = localStorage.getItem('myself');

  return !!isItMe;
}

(function ($, window, document, undefined) {
  $(function () {
    document.addEventListener('touchstart', null, {passive: true});

    if (!isDev() && !isItMe()) {
      $('.section--recommend__slide__link').on('click', function (e) {
        var clickedBlockName = $(this).find('.section--recommend__title').text();
      });
    }

    initMap();
    manageMenuToggle();
    handleNavigation();
    initCarousels();
    handleTabs();
    initViewer();
    toggleContactForm();
    handleSubmitForm();
    hideRealizationOnHover();
    if (!localStorage.getItem('cookies_accepted')) {
      addCookiesMessage();
    }
  });

})(jQuery, window, document);


